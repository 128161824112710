<template>
  <div class="monthModel">
    <div class="margin-bottom-20" v-if="targetInfo">
      <el-popover placement="right-start" width="400" trigger="hover">
        <div class="font-size-12 line-height-large">
          <div>
            <div><span class="color-sub">数据名称：</span>{{ targetInfo.desc.name }}</div>
            <div><span class="color-sub">数据单位：</span>{{ targetInfo.desc.unit }}</div>
            <div><span class="color-sub">市场名称：</span>{{ targetInfo.desc.market }}</div>
            <div><span class="color-sub">规格牌号：</span>{{ targetInfo.desc.dataModel }}</div>
            <div><span class="color-sub">更新频率：</span>{{ targetInfo.desc.frequency }}</div>
            <div><span class="color-sub">统计日期：</span>{{ targetInfo.desc.time }}</div>
            <div><span class="color-sub">样本说明：</span>{{ targetInfo.desc.explain }}</div>
            <div><span class="color-sub">方法论：</span>{{ targetInfo.desc.func }}</div>
            <div><span class="color-sub">数据来源：</span>{{ targetInfo.desc.dataSource }}</div>
          </div>
        </div>
        <span slot="reference">
            <span class="font-size-20">{{targetInfo.targetName}}</span>
        </span>
      </el-popover>
    </div>
    <el-row :gutter="20" type="flex">
      <el-col :span="24">
        <div class="column flex-row" v-loading="modelInfoLoading">
            <div>
              <p class="color-sub font-size-12 text-nowrap">模型框架</p>
              <p class="margin-top-5">{{modelInfo.agriName||"-"}}</p>
            </div>
            <div class="border-right margin-tb-5 margin-lr-30"></div>
            <div>
              <p class="color-sub font-size-12 text-nowrap">历史估计周期</p>
              <p class="margin-top-5"><span>{{modelInfo.factorStart}}</span>至<span>{{modelInfo.factorEnd}}</span></p>
            </div>
            <div class="border-right margin-tb-5 margin-lr-30"></div>
            <div>
              <p class="color-sub font-size-12 text-nowrap">模型更新</p>
              <p class="margin-top-5">{{modelInfo.addTime | dateFormat('yyyy-MM-dd hh:mm')}}</p>
            </div>
            <div class="border-right margin-tb-5 margin-lr-30"></div>
            <div>
              <p class="color-sub font-size-12 text-nowrap">模型自估预测更新</p>
              <p class="margin-top-5">{{modelInfo.aiTime | dateFormat('yyyy-MM-dd hh:mm')}}</p>
            </div>
            <div class="border-right margin-tb-5 margin-lr-30"></div>
            <div>
              <p class="color-sub font-size-12 text-nowrap">卓创赋值预测更新</p>
              <p class="margin-top-5">{{modelInfo.sciTime | dateFormat('yyyy-MM-dd hh:mm')}}</p>
            </div>
            <div class="border-right margin-tb-5 margin-lr-30"></div>
            <div>
              <p class="color-sub font-size-12 text-nowrap">滨化赋值预测更新</p>
              <p class="margin-top-5">{{modelInfo.bfTime | dateFormat('yyyy-MM-dd hh:mm')}}</p>
            </div>
        </div>
      </el-col>
      <el-col :span="24">
        <div class="column flex-column" v-loading="modelForecast.loading">
          <div class="flex-row align-items-start justify-between">
            <div>
                <div>
                  <span>预测结果</span>
                </div>
                <div class="margin-top-5 font-size-12">
                  <span class="color-sub">
                    预测周期：<template v-if="modelForecast.data.startTime||modelForecast.data.endTime">{{modelForecast.data.startTime}}至{{modelForecast.data.endTime}}</template>
                  </span>
                </div>
            </div>
            <el-tooltip effect="dark" content="展示数据列表" placement="top">
              <div class="link-text" @click="modelForecast.dataViewVisible=true">数据详情</div>
            </el-tooltip>
          </div>
          <Chart :option="modelForecastChart" class="flex-auto"></Chart>
        </div>
        <el-dialog custom-class="custom-dialog" :visible.sync="modelForecast.dataViewVisible"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :append-to-body="true" top="50px">
          <div slot="title">
            <div class="flex-row align-items-center justify-between margin-right-40">
              <div class="el-dialog__title">预测结果-数据详情</div>
            </div>
          </div>
          <el-table
            v-if="modelForecast.data.aiValue"
            :height="$root.docHeight-220"
            :data="modelForecast.data.aiValue"
            size="medium"
            style="width: 100%">
            <div slot="empty"><el-empty :image-size="150"></el-empty></div>
            <el-table-column label="时间">
              <template slot-scope="props">{{props.row.dataCycle}}</template>
            </el-table-column>
            <el-table-column label="模型自估预测">
              <template slot-scope="props">{{props.row.value | numFormat(2,'-')}}</template>
            </el-table-column>
            <el-table-column label="卓创赋值预测">
              <template slot-scope="props">{{modelForecast.data.sciValue[props.$index].value | numFormat(2,'-')}}</template>
            </el-table-column>
            <el-table-column label="滨化赋值预测">
              <template slot-scope="props">{{modelForecast.data.bfValue[props.$index].value | numFormat(2,'-')}}</template>
            </el-table-column>
          </el-table>
        </el-dialog>
      </el-col>
      <el-col :span="24">
        <div class="column flex-column" v-loading="divisors.loading">
          <div class="flex-row align-items-start justify-between">
            <div>
              <div class="column-tabs">
                <div :class="{'active':divisors.type=='sci'}" @click="divisors.type='sci';getDivisors()">卓创因子赋值</div>
                <div :class="{'active':divisors.type=='befar'}" @click="divisors.type='befar';getDivisors()">滨化因子赋值</div>
                <div :class="{'active':divisors.type=='ai'}" @click="divisors.type='ai';getDivisors()">模型因子自估</div>
              </div>
              <div class="margin-top-5 font-size-12">
                <span class="color-sub margin-right-20">
                  统计周期：<template v-if="divisors.data.startTime||divisors.data.endTime">{{divisors.data.startTime}}至{{divisors.data.endTime}}</template>
                </span>
                <span class="color-sub margin-right-20" v-if="divisors.type=='ai'">
                  模型因子自估是模型基于因子联合预测方式，以价格预测为目标，通过对历史数据评估和拟合，推演出的因子结果，不作为因子供需预测的依据和参考。
                </span>
              </div>
            </div>
            <!-- <div>
              <el-select v-model="divisors.modelCycle" size="mini" @change="getDivisors">
                <el-option :value="1" label="移动1期预测方案"></el-option>
                <el-option :value="4" label="移动4期预测方案"></el-option>
              </el-select>
            </div> -->
          </div>
          <div class="margin-top-10">
            <el-table
              ref="divisorsTable"
              :max-height="$root.docHeight-250"
              :key="targetId+'-'+divisors.type+'-'+divisors.modelCycle+'-'+divisors.showCycle+'-'+$root.docWidth"
              size="medium"
              :data="divisors.data.items">
              <div slot="empty"><el-empty :image-size="150"></el-empty></div>
              <el-table-column
                min-width="150"
                fixed="left"
                :show-overflow-tooltip="false"
                label="因子名称">
                <template slot-scope="props">
                  <el-popover placement="right-start" width="350" trigger="hover">
                    <div class="font-size-12 line-height-large" style="max-height: 50vh; overflow:auto;">
                      <div v-for="(item,index) in props.row.children" :key="index" :class="{'padding-top-10':index>0,'margin-top-10':index>0,'border-top':index>0}">
                        <div><span class="color-sub">数据名称：</span>{{item.factorName}}</div>
                        <div><span class="color-sub">数据单位：</span>{{item.unit}}</div>
                        <div><span class="color-sub">数据类型：</span>{{item.typeName}}</div>
                        <div><span class="color-sub">更新频率：</span>{{item.modelFreq}}</div>
                        <div><span class="color-sub">统计日期：</span>{{item.startDate}}-{{item.endDate}}</div>
                        <div><span class="color-sub">方法论：</span>{{item.func}}</div>
                        <div><span class="color-sub">数据来源：</span>{{item.dataSource}}</div>
                      </div>
                    </div>
                    <span slot="reference" class="display-inline-block">
                      <span class="text-overflow-ellipsis-block" @click="getDivisorChart(props.row)">
                        <span class="custom-tag margin-right-10" color="red" size="mini" v-if="props.row.isOut">外生</span>
                        <span class="link-text">{{props.row.divisorName}}</span>
                      </span>
                    </span>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column label="单位" prop="unit"></el-table-column>
              <el-table-column v-for="(item,index) in divisors.data.dataList" :key="index" :label="item.dateCycleShow">
                <template slot-scope="props"><span>{{ item[props.row.id].value | numFormat(2,'-')}}</span></template>
              </el-table-column>
              <el-table-column
                v-if="this.divisors.type!='ai'"
                label="预估说明"
                :show-overflow-tooltip="true"
                prop="reason"
                min-width="150"
                fixed="right"
              >
              </el-table-column>
            </el-table>
          </div>
        </div>
         <!-- 因子数据图详情弹窗 -->
        <el-dialog custom-class="custom-dialog" :title="divisors.active.divisorName" append-to-body :close-on-click-modal="false" width="80%" top="15vh" :visible.sync="divisorData.visible">
          <div class="flex-column" style="height: calc(70vh - 80px);" v-loading="divisorData.loading">
            <div><span>赋值说明：</span>{{divisorData.data.Reason}}</div>
            <Chart :option="divisorChart" class="flex-auto"></Chart>
          </div>
        </el-dialog>
      </el-col>
      <el-col :span="24">
        <div class="column flex-column" v-loading="verifyRate.loading">
          <div class="flex-row align-items-start justify-between">
            <div>
              <div>
                <span>模型精度</span>
              </div>
              <div class="margin-top-5 font-size-12">
                <span class="color-sub">
                  验证周期：<template v-if="verifyRate.data.startTime||verifyRate.data.endTime">{{verifyRate.data.startTime}}至{{verifyRate.data.endTime}}</template>
                </span>
              </div>
            </div>
            <el-tooltip effect="dark" content="展示数据列表" placement="top">
              <div class="link-text"  @click="verifyRate.dataViewVisible=true">数据详情</div>
            </el-tooltip>
          </div>
          <div class="flex-row margin-top-20">
            <div style="margin-right:5%">
              <p class="color-sub">移动1期平均偏差率</p>
              <p class="font-size-20">{{verifyRate.data.step1DeviationRateAvg | numFormat(2,'-')}}%</p>
            </div>
            <div style="margin-right:5%">
              <p class="color-sub">移动4期平均偏差率</p>
              <p class="font-size-20">{{verifyRate.data.step4DeviationRateAvg | numFormat(2,'-')}}%</p>
            </div>
          </div>
          <Chart :option="verifyRateChart" class="flex-auto"></Chart>
        </div>
        <el-dialog custom-class="custom-dialog" width="80%" :visible.sync="verifyRate.dataViewVisible"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :append-to-body="true" top="50px">
          <div slot="title">
            <div class="flex-row align-items-center justify-between margin-right-40">
              <div class="el-dialog__title">模型精度-数据详情</div>
            </div>
          </div>
          <el-table
            :height="$root.docHeight-220"
            :data="verifyRate.data.dataList"
            size="medium"
            style="width: 100%">
            <div slot="empty"><el-empty :image-size="150"></el-empty></div>
            <el-table-column label="时间">
              <template slot-scope="props">{{props.row.dataCycle}}</template>
            </el-table-column>
            <el-table-column label="真实值">
              <template slot-scope="props">{{props.row.his | numFormat(2,'-')}}</template>
            </el-table-column>
            <el-table-column label="移动1期" align="center">
              <el-table-column label="验证值">
                <template slot-scope="props">{{props.row.step1Value | numFormat(2,'-')}}</template>
              </el-table-column>
              <el-table-column label="偏差值">
                <template slot-scope="props">{{props.row.step1DeviationValue | numFormat(2,'-')}}</template>
              </el-table-column>
              <el-table-column label="偏差率">
                <template slot-scope="props">{{props.row.step1DeviationRate | numFormat(2,'-')}}{{ $numFormat(props.row.step1DeviationRate)?'%':'' }}</template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="移动4期" align="center">
              <el-table-column label="验证值">
                <template slot-scope="props">{{props.row.step4Value | numFormat(2,'-')}}</template>
              </el-table-column>
              <el-table-column label="偏差值">
                <template slot-scope="props">{{props.row.step4DeviationValue | numFormat(2,'-')}}</template>
              </el-table-column>
              <el-table-column label="偏差率">
                <template slot-scope="props">{{props.row.step4DeviationRate | numFormat(2,'-')}}{{ $numFormat(props.row.step4DeviationRate)?'%':'' }}</template>
              </el-table-column>
            </el-table-column>
          </el-table>
        </el-dialog>
      </el-col>
      <el-col :span="24">
        <div class="column flex-column" v-loading="divisorContri.loading">
          <div class="flex-row align-items-start justify-between">
            <div>
              <div><span>因子贡献度</span></div>
              <div class="margin-top-5 font-size-12">
                <span class="color-sub">
                  统计周期：<template v-if="divisorContri.data.startTime||divisorContri.data.endTime">{{divisorContri.data.startTime}}至{{divisorContri.data.endTime}}</template>
                </span>
              </div>
            </div>
            <el-tooltip effect="dark" content="展示数据列表" placement="top">
              <div class="link" @click="divisorContri.dataViewVisible=true">数据详情</div>
            </el-tooltip>
          </div>
          <Chart :option="divisorContriChart" :legendAutoHeight="true" class="flex-auto"></Chart>
        </div>
        <el-dialog custom-class="custom-dialog" title="因子贡献度-数据视图"  :visible.sync="divisorContri.dataViewVisible" :append-to-body="true" width="80%" top="50px"
          :close-on-click-modal="false"
          :close-on-press-escape="false">
          <div slot="title">
              <div class="flex-row align-items-center justify-between margin-right-40">
                <div class="el-dialog__title">因子贡献度-数据详情</div>
              </div>
            </div>
            <el-table v-if="divisorContri.data.items" :data="divisorContri.data.items"
              :height="$root.docHeight-220"
              size="medium" style="width: 100%"  @sort-change="divisorContriDataViewSort">
              <el-table-column fixed="left" :show-overflow-tooltip="true" prop="divisorName" min-width="200" label="因子"></el-table-column>
              <el-table-column  v-for="(item,index) in divisorContri.data.dataList" :key="index" :prop="item.dataCycle" :label="item.dataCycle" min-width="105" align="center" sortable="custom">
                <template slot-scope="props">
                  <span>{{$numFormat(parseFloat(item[props.row.divisorId])*100,2,'-')}}</span>
                  {{$numFormat(item[props.row.divisorId])?'%':''}}
                </template>
              </el-table-column>
            </el-table>
        </el-dialog>
      </el-col>


    </el-row>
    
  </div>
</template>

<script>
import Chart from "@/components/Chart";
export default {
  name: 'MonthModel',
  components:{
    Chart,
  },
  props:{
    curProduct:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  data (){
    return{
      modelFreq:'week',
      productId:this.curProduct.productId,
      targetId:this.curProduct.targetId,

      targetInfo:null,//目标价信息

      modelInfoLoading:false,
      modelInfo:{},//顶部模型信息

      modelForecast:{//模型预测
        loading:false,
        data:{},
        dataViewVisible:false,
      },

      divisors:{//因子构成/因子赋值
        loading:false,
        type:'sci',//sci/befar
        modelCycle:1,
        showCycle:1,
        data:{},
        active:{},
      },
      divisorData:{
        loading:false,
        visible:false,
        data:{}
      },

      verifyRate:{//模型精度
        loading:false,
        data:{},
        dataViewVisible:false,
      },
      divisorContri:{//因子贡献度
        loading:false,
        data:{},
        dataViewVisible:false,
      },

    }
  },
  computed:{
    //预测结果图表
    modelForecastChart(){
      let chartData=this.modelForecast.data
      if(chartData.items){
        let opt =JSON.parse(JSON.stringify(this.$defaultChartOption)) 
        opt.yAxis.name=chartData.items[0].unit
        opt.xAxis.boundaryGap = false
        opt.xAxis.data=chartData.dataList.map(v=>{return v.dataCycle})
        // opt.dataZoom.startValue=opt.xAxis.data.length-15
        opt.legend.data=chartData.items.map(v=>{
          return {
            name:v.name,
            unit:v.unit,
            itemStyle:{
                opacity:0
            }
          }
        })
        opt.series=chartData.items.map(v=>{
          return {
            name: v.name,
            id:v.id,
            type: 'line', 
            connectNulls:true,
            lineStyle:{
              type:v.id=='his'?"solid":"dashed",
            },
            data: chartData.dataList.map(vv=>{return vv[v.id]}),
          }
        }) 
        opt.tooltip.formatter = (params)=>{
          let str=`<div>${params[0].axisValue}</div>`
          params.forEach((val)=>{
            if(this.$numFormat(val.data)){
              let item=chartData.items[val.seriesIndex]
              if(item.id!='his'){
                if(!this.$numFormat(chartData.dataList[val.dataIndex]['his'])||!params.some(v=>v.seriesId=='his')){
                  str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit}</div>`
                }
              }else{
                str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit}</div>`
              }
            }
          })
          return str
        }
        return opt
      }else{
        return null
      }
    },
    //因子详情图表
    divisorChart(){
      let chartData=this.divisorData.data
      if(chartData.items){
        let opt =JSON.parse(JSON.stringify(this.$defaultChartOption)) 
        opt.yAxis.name=this.divisors.active.unit
        opt.xAxis.boundaryGap = false
        opt.xAxis.data=chartData.dataList.map(v=>{return v.dateCycleShow})
        // opt.dataZoom.startValue=opt.xAxis.data.length-15
        opt.legend.data=chartData.items.map(v=>{
          return {
            name:v.name,
            unit:this.divisors.active.unit,
            itemStyle:{
                opacity:0
            }
          }
        })
        opt.series=chartData.items.map(v=>{
          return {
            name: v.name,
            id:v.id,
            type: 'line', 
            connectNulls:true,
            lineStyle:{
              type:v.id=='hisValue'?"solid":"dashed",
            },
            data: chartData.dataList.map(vv=>{return vv[v.id]}),
          }
        }) 
        opt.tooltip.formatter = (params)=>{
          let str=`<div>${params[0].axisValue}</div>`
          params.forEach((val)=>{
            if(this.$numFormat(val.data)){
              let item=chartData.items[val.seriesIndex]
              if(item.id!='hisValue'){
                if(!this.$numFormat(chartData.dataList[val.dataIndex]['hisValue'])||!params.some(v=>v.seriesId=='hisValue')){
                  str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit}</div>`
                }
              }else{
                str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit}</div>`
              }
            }
          })
          return str
        }
        return opt
      }else{
        return null
      }
    },
    //模型精度图表
    verifyRateChart(){
      let chartData=this.verifyRate.data
      if(chartData.items){
        let opt =JSON.parse(JSON.stringify(this.$defaultChartOption)) 
        opt.xAxis.boundaryGap = true
        opt.xAxis.data=chartData.dataList.map(v=>{return v.dataCycle})
        opt.legend.data=chartData.items.filter(v=>{return v.id!='step1DeviationValue'&&v.id!='step3DeviationValue'&&v.id!='step4DeviationValue'&&v.id!='step12DeviationValue'}).map(v=>{
          return {
            name:v.name,
            unit:v.unit,
            itemStyle:{
                opacity: v.id!='step1DeviationRate'&&v.id!='step3DeviationRate'&&v.id!='step4DeviationRate'&&v.id!='step12DeviationRate'?0:1
            }
          }
        })
        opt.yAxis=[
          {
            type: 'value',
            name:chartData.items.find(v=>{return v.id=='his'}).unit,
            nameLocation: 'end' ,
            nameGap: 5,
            nameTextStyle:{
              align:'left'
            },
            splitLine: {
              lineStyle: {
                type:'dashed'
              }
            },
            scale:true,
          },
          {
            type: 'value',
            name:'',
            nameLocation: 'end' ,
            nameGap: 5,
            nameTextStyle:{
              align:'left'
            },
            splitLine:{
              show:false
            },
            axisLabel:{
              formatter: '{value}%'
            },
            min:0,
            max:100
          }
        ]
        opt.series=chartData.items.filter(v=>{return v.id!='step1DeviationValue'&&v.id!='step3DeviationValue'&&v.id!='step4DeviationValue'&&v.id!='step12DeviationValue'}).map(v=>{
          let isLine=(v.id!='step1DeviationRate'&&v.id!='step3DeviationRate'&&v.id!='step4DeviationRate'&&v.id!='step12DeviationRate')
          return isLine? {
            name: v.name,
            type: 'line', 
            connectNulls:true,
            lineStyle:{
              type:v.id!='his'?"dashed":"solid"
            },
            data: chartData.dataList.map(vv=>{return vv[v.id]}),
          }:{ 
            name: v.name,
            type: 'bar', 
            yAxisIndex:1,
            barMaxWidth:15,  

            itemStyle:{ 
              opacity:0.5
            },
            data: chartData.dataList.map(vv=>{return vv[v.id]}),
          }
        }) 
        opt.tooltip.formatter = (params)=>{
          let str=`<div>${params[0].axisValue}</div>`
          params.forEach((val)=>{
            if(this.$numFormat(val.data)){
              str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit||""}</div>`
            }
          })
          return str
        }
        return opt
      }else{
        return null
      }
    },
    divisorContriChart(){
      let chartData=this.divisorContri.data
      if(chartData.items){
        let opt =JSON.parse(JSON.stringify(this.$defaultChartOption)) 
        opt.yAxis.axisLabel.show=false;
        opt.xAxis.data=chartData.dataList.map(v=>{return v.dataCycle})
        opt.tooltip.axisPointer={type: 'shadow'};
        opt.tooltip.formatter = (params)=>{
          let sortArr=params.sort((a,b)=>{return b.value-a.value})
          let str=`<div>${params[0].axisValue}</div><div style="width:600px;display:flex;justify-content: space-between;flex-wrap:wrap;font-size:14px">`
          sortArr.forEach((val)=>{
            if(this.$numFormat(val.data)){
              str+=`<div style="width:48%; display:flex;"><div style="flex:0 0 10px">${val.marker}</div><div style="word-break: break-word;white-space:normal;">${val.seriesName}：<span style="font-weight:bold">${this.$numFormat(val.value*100,2)}%</span></div></div>`
            }
          })
          return str+'</div>'
        }
        opt.legend.data=chartData.items.map(v=>{
          return v.divisorName
        })
        
        opt.dataZoom.bottom=10
        opt.dataZoom.left=40
        opt.dataZoom.right=40
        opt.series=chartData.items.map(v=>{
          return {
            name: v.divisorName,
            type: 'bar', 
            barWidth:"25%",
            barMaxWidth:50,
            barMinWidth:5,
            stack: 'total',
            emphasis: {
              focus: 'series',
            },
            data: chartData.dataList.map(vv=>{return vv[v.divisorId]}),
          }
        })
        return opt
      }else{
        return null
      }
    },
    divisorPulseChart(){
      let chartData=this.divisorPulse.data
      if(chartData.items){
        let opt =JSON.parse(JSON.stringify(this.$defaultChartOption)) 
        opt.xAxis.boundaryGap = false
        opt.xAxis.data=chartData.dataList.map(v=>{return v.month})
        // opt.dataZoom.startValue=opt.xAxis.data.length-15
        opt.legend.data=chartData.items.map(v=>{
          return {
            name:v.name,
            itemStyle:{
                opacity:0
            }
          }
        })
        let lineStyle=[{
                type:"solid",
                opacity:1
              },{
                type:"dashed",
                opacity:0.3
              }],
            areaStyle= [{
                color:"#999",
                opacity:0.1,
                origin: 'start',
              },{
                color:this.$store.state.theme=="light"?"#fff":"#1b202c",
                opacity:1,
                origin: 'start',
              }]
        opt.series=chartData.items.map(v=>{
          return {
            name: v.name,
            type: 'line', 
            lineStyle:v.id=='MValue'?lineStyle[0]:lineStyle[1],
            areaStyle:v.id=='HValue'?areaStyle[0]:v.id=='LValue'?areaStyle[1]:{opacity:0},
            z:v.id=='HValue'?-2:v.id=='LValue'?-1:1,
            showSymbol:false,
            emphasis:{
              disabled:true
            },
            data: chartData.dataList.map(vv=>{return vv[v.id]}),
          }
        }) 
        
        return opt
      }else{
        return null
      }
    }
  },
  created(){

  },
  mounted(){
    this.getData()
  },
  methods:{
    async getData(){
      if(this.targetId){
        this.getTargetInfo()
        this.getModelInfo()
        this.getModelForecast()
        this.getVerifyRate()
        this.getDivisorContri()
        this.getDivisors()

        
      }
    },
    //获取目标价信息
    getTargetInfo(){
      let request=this.$instance.get('/index/targetTopInfo',{
          params: {
           ...this.$store.state.basicParams,
           targetId:this.targetId,
           modelFreq:this.modelFreq
          },
      })
      request.then(res=>{
        if(res.data.code==0){
          this.targetInfo=res.data.info
        }else{
          this.targetInfo=null
        }
      })
      request.catch(()=>{
        this.targetInfo=null
      })
      return request
    },
    //获取顶部模型信息
    getModelInfo(){
      this.modelInfoLoading=true;
      let request=this.$instance.get('/ai/modelInfo',{
          params: {
            ...this.$store.state.basicParams,
            targetId:this.targetId,
            modelFreq:this.modelFreq
          },
      })
      request.then(res=>{
        this.modelInfoLoading=false;
        if(res.data.code==0){
          this.modelInfo=res.data.info
        }
        else{
          this.modelInfo={}
        }
      })
      request.catch(()=> {
        this.modelInfoLoading=false;
        this.modelInfo={}
      }); 
      return request
    },

   //模型预测
    getModelForecast(){
      this.modelForecast.loading=true;
      this.$instance.get('/index/getPredictValue',{
          params: {
            ...this.$store.state.basicParams,
            targetId:this.targetId,
            modelFreq:this.modelFreq
          },
      })
      .then(res=>{
        this.modelForecast.loading=false
        if(res.data.code==0){
          this.modelForecast.data=res.data.info
        }
        else{
          this.modelForecast.data={}
        }
      })
      .catch(()=>{
        this.modelForecast.loading=false
        this.modelForecast.data={}
      })
    },
    
    //获取模型因子赋值
    getDivisors(){
      this.divisors.loading=true;
      let request=this.$instance.get('/prediction/getDivisorValue',{
          params: {
            ...this.$store.state.basicParams,
            targetId:this.targetId,
            modelFreq:this.modelFreq,
            expertType:this.divisors.type,
            modelCycle:this.divisors.modelCycle,
            showCycle:this.divisors.showCycle
          },
      })
      request.then(res=>{
        this.divisors.loading=false
        if(res.data.code==0){
          this.divisors.data=res.data.info
          this.$nextTick(()=>{
            this.$refs.divisorsTable.doLayout()
          })
        }
        else{
          this.divisors.data={}
        }
      })
      request.catch(()=> {
        this.divisors.loading=false
        this.divisors.data={}
      }); 
      return request
    },
  //获取因子走势图详情
    getDivisorChart(divisor){
      this.divisors.active=divisor
      this.divisorData={
        loading:true,
        visible:true,
        data:{}
      }
      this.$instance.get('/prediction/getDivisorDetail',{
          params: {
            ...this.$store.state.basicParams,
            targetId:this.targetId,
            expertType:this.divisors.type,
            modelFreq:this.modelFreq,
            divisorId:divisor.divisorId,
            isOut:divisor.isOut,
            showCycle:this.divisors.showCycle
          },
      })
      .then(res=>{
        this.divisorData.loading=false;
        if(res.data.code==0){
          this.divisorData.data=res.data.info;
        }else{
          this.divisorData.data={}
        }
      })
      .catch(()=> {
        this.divisorData.loading=false;
        this.divisorData.data={}
      }); 
    },
    //模型精度
    getVerifyRate(){
      this.verifyRate.loading=true;
      this.$instance.get('/prediction/getBackVerify',{
          params: {
            ...this.$store.state.basicParams,
            targetId:this.targetId,
            modelFreq:this.modelFreq,
          },
      })
      .then(res=>{
        this.verifyRate.loading=false
        if(res.data.code==0){
          this.verifyRate.data=res.data.info
        }
        else{
          this.verifyRate.data={}
        }
      })
      .catch(()=>{
        this.verifyRate.loading=false
        this.verifyRate.data={}
      })
    },
    //获取因子贡献度
    getDivisorContri(){
      this.divisorContri.loading=true;
      this.$instance.get('/ai/divisorContriByCycle',{
          params: {
            ...this.$store.state.basicParams,
            targetId:this.targetId,
            modelFreq:this.modelFreq,
          },
      })
      .then(res=>{
        this.divisorContri.loading=false
        if(res.data.code==0){
          this.divisorContri.data=res.data.info
        }
        else{
          this.divisorContri.data={}
        }
      })
      .catch(()=>{
        this.divisorContri.loading=false
        this.divisorContri.data={}
      })
    },
    //因子贡献度数据视图列表排序
    divisorContriDataViewSort({order,prop}){
      let data=this.divisorContri.data.dataList.find(v=>v.dataCycle===prop)
      if(order=="ascending"){
        //升序
        this.divisorContri.data.items = this.divisorContri.data.items.sort((a, b) => {
          return  data[a.divisorId]- data[b.divisorId]
        })
      }else{
        this.divisorContri.data.items = this.divisorContri.data.items.sort((a, b) => {
          return  data[b.divisorId]- data[a.divisorId]
        })
      }
    },

  },
  
  watch: {
    "curProduct" : {
     handler:function(val){
        this.productId=val.productId
        this.targetId=val.targetId
        this.getData()
     },
     deep:true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss" scoped>

 
</style>
<style>

</style>